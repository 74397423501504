import api from '../libs/axios'

export const fetchPlatforms = (query) => api.get(`/api/platform${query ? `?${query}` : ''}`)

export const fetchCategories = () => api.get('/api/lists/categories')

export const createPlatform = (data) => api.post('api/platform', {...data})

export const editPlatform = (data) => api.patch(`api/platform/${data.id}`, {...data})

export const activatePlatform = (id) => api.post(`api/platform/${id}/activate`)

export const deactivatePlatform = (id) => api.post(`api/platform/${id}/deactivate`)

