<template>
  <div v-if="isLoadingLists" class="d-flex justify-content-center align-items-center mt-5">
    <b-spinner variant="primary" label="Spinning" />
  </div>
  <validation-observer v-else ref="form" v-slot="{ invalid, errors }">
    <BForm class="py-2" @submit.prevent="handleSubmit">
      <b-row>
        <b-col md="4" lg="3">
      <form-input id="name" label="Название площадки" v-model="formData.name" vid="name" :rules="validationSchema.name" />
        </b-col>
        <b-col md="4" lg="3">
          <label for="comment">Комментарий</label>
          <b-form-textarea
              id="comment"
              v-model="formData.comment"
              placeholder="Оставьте комментарий"
              rows="1"
              max-rows="6"
          />
        </b-col>
      </b-row>

      <b-row class="min-height-360 mt-2 campaign-form__multiselect">
        <b-col class="campaign-form__multiselect-leftcol" id="to-filter" sm="3">
          <ToFilterSelect :model-value="filterToShow" @update:model-value="setFilterToShow"
                          :proxy-names="proxyForFilterSelect"
                          :filters-options="filtersOptions" :state="formData" :errors="errors"
                          :facets="{...namedLists, category_ids: [{value: categoryList}]}" />
        </b-col>

        <b-col v-if="can('read', 'publishers-list')" v-show="filterToShow === 'publishers'" class="placements-form__select-col">
          <b-row class="d-flex" style="gap: 20px">
            <div class="col pr-0 mt-1 mt-md-0">
            <form-input-wrap
                id="user_id"
                vid="user_id"
                class="w-100"
                label="Паблишер"
                label-hidden
                :rules="validationSchema.publishers"
            >
            <MultiselectCheckboxesWithValidation
                class="flex-1-1-0"
                name="user_id"
                :facets="publishersList"
                :model-value="formData.user_id"
                v-model="formData.user_id"
                @update:model-value="pickMultiselect($event, 'user_id')"
                single
                track-by="id"
                is-paginated
                :lists="namedLists"
                is-small
                @paginate="handlePaginateList"
                @search="handleListsSearch"/>
            </form-input-wrap>
            </div>
          </b-row>
        </b-col>

        <b-col v-show="filterToShow === 'advertisement_types'" class="placements-form__select-col">
          <b-row class="d-flex" style="gap: 20px">
            <div class="col pr-0 mt-1 mt-md-0">
            <form-input-wrap
                id="advertisement_types"
                vid="advertisement_types"
                class="w-100"
                label="Тип рекламы"
                label-hidden
                :rules="validationSchema.advertisement_types"
            >
              <MultiselectCheckboxesWithValidation
                  class="flex-1-1-0"
                  name="advertisement_types"
                  :facets="advertisementTypesList"
                  v-model="formData.advertisement_types"
                  :state="formData.advertisement_types"
                  :model-value="formData.advertisement_types"
                  is-small
                  @update:model-value="pickMultiselect($event, 'advertisement_types')"
                  :disable-method="disableForAdvertisementType"
                  track-by="id"
                  :is-searchable="false"/>
            </form-input-wrap>
            </div>
          </b-row>
        </b-col>

        <b-col v-show="filterToShow === 'category_ids'" class="placements-form__select-col">
          <b-row class="d-flex" style="gap: 20px">
            <div class="col pr-0 mt-1 mt-md-0">
            <form-input-wrap
                id="category_ids"
                vid="category_ids"
                class="w-100"
                label="Категории"
                label-hidden
                :rules="validationSchema.category_ids"
            >
              <MultiselectCheckboxesWithValidation
                  class="flex-1-1-0"
                  name="category_ids"
                  :facets="categoryList"
                  v-model="formData.category_ids"
                  :state="formData.category_ids"
                  :model-value="formData.category_ids"
                  is-small
                  @update:model-value="pickMultiselect($event, 'category_ids')"
                  track-by="id"
                  :is-searchable="false"/>
            </form-input-wrap>
            </div>
          </b-row>
        </b-col>
      </b-row>

      <div class="py-2">
        <loader-button
            type="submit"
            variant="primary"
            :disabled="isSubmitting"
            :is-submitting="isSubmitting"
        >
          {{ isEditor ? 'Сохранить' : 'Создать' }}
        </loader-button>
      </div>
    </BForm>
  </validation-observer>
</template>

<script>
import ToFilterSelect from "@/components/ToFilterSelect"
import MultiselectCheckboxesWithValidation from "@/components/MultiselectCheckboxesWithValidation"
import {
  BForm,
  BButton,
  BFormSelect,
  BFormInput,
  BFormSelectOption,
  BFormTextarea,
  BBadge,
  BDropdown,
  BDropdownItem,
  BSpinner,
  BFormRadio,
  BFormGroup,
  BFormCheckbox
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import FormInput from '@/components/FormInput'
import FormInputWrap from '@/components/FormInputWrap'
import LoaderButton from '@/components/LoaderButton'
import MultipleSelect from '@/components/MultipleSelect'
import Multiselect from 'vue-multiselect'

import * as validations from '@validations'
import { ValidationProvider } from 'vee-validate'
import {
  fetchAdvertisementType,
  fetchPublishersList
} from '@/api/lists'
import {fetchCategories} from "@/api/platforms"
import MultiselectValidation from "@/components/MultiselectWithValidation"
import {useUtils as useAclUtils} from "@core/libs/acl"
import {ref} from "@vue/composition-api/dist/vue-composition-api"
import {reactive} from "@vue/composition-api"
import statisticLists from "@/use/statisticLists"
import formsLists from "@/use/formsLists"


export default {
  name: 'PlatformForm',
  components: {
    MultiselectCheckboxesWithValidation,
    ToFilterSelect,
    ValidationProvider,
    BFormCheckbox,
    BForm,
    BFormInput,
    BFormRadio,
    BFormGroup,
    BButton,
    BFormSelect,
    BFormTextarea,
    BFormSelectOption,
    BBadge,
    BDropdown,
    BDropdownItem,
    BSpinner,
    ValidationObserver,
    FormInput,
    FormInputWrap,
    LoaderButton,
    MultipleSelect,
    Multiselect,
  },
  props: {
    isSubmitting: {
      type: Boolean,
      required: false,
      default: () => false
    },
    validationSchema: {
      type: Object,
      required: true
    },
    platform: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  // async created() {
  //   this.roleId = parseInt(localStorage.getItem('userRoleId'), 10)
  //   if (this.platform && Object.keys(this.platform).length) {
  //     this.isEditor = true;
  //     this.formData = {
  //       name: this.platform.name ?? '',
  //       id: this.platform.id || '',
  //       user_id: this.platform.publisher && [this.platform.publisher.id] || '',
  //       advertisement_types: this.platform.advertisement_types && this.platform.advertisement_types.map((item) => item.id) || '',
  //       category_ids: this.platform.categories && this.platform.categories.map((item) => item.id)  || [],
  //       comment: this.platform.comment || ''
  //     }
  //   }
  //
  //   this.isLoadingLists = true;
  //
  //   Promise.all([
  //     fetchAdvertisementType(),
  //     fetchCategories(),
  //     fetchPublishersList(null, this.roleId)])
  //       .then(values => {
  //         this.advertisementTypes = values[0].data.data
  //         this.categories = values[1].data.data
  //         this.publishersList = values[2].data.data
  //       })
  //       .finally(() => {
  //         this.isLoadingLists = false;
  //       })
  // },
  setup(props, {emit}) {
    const {can} = useAclUtils();
    const isLoadingLists = ref(true)
    const filterToShow = ref('campaigns')
    const roleId = parseInt(localStorage.getItem('userRoleId'), 10)

    const formData = reactive({
      name: '',
      category_ids: [],
      is_active: true,
      advertisement_types: [],
      user_id: [],
      comment: '',
      id: null,
    })

    const isEditor = ref(false)

    const form = ref(null);

    if (props.platform && Object.keys(props.platform).length) {
        isEditor.value = true;

        formData.name = props.platform.name || '';
        formData.id = props.platform.id;
        formData.user_id = props.platform.publisher && [props.platform.publisher.id] || [];
        formData.advertisement_types = props.platform.advertisement_types && props.platform.advertisement_types.map((item) => item.id) || '',
        formData.category_ids = props.platform.categories && props.platform.categories.map((item) => item.id)  || [],
        formData.comment = props.platform.comment || ''
    }

    const {
      advertisementTypesList,
      publishersList,
      namedLists,
      handleListsSearch,
      handleLoadLists,
      handlePaginateList,
    } = formsLists()

    const categoryList = ref([])

    let filtersOptions = [
      {
        text: 'Тип рекламы',
        value: 'advertisement_types'
      },
      {
        text: 'Категории',
        value: 'category_ids'
      }
    ]

    const setFilterToShow = (value) => {
      filterToShow.value = value;
    }

    const proxyForFilterSelect = {
      advertisement_types: 'advertisement_types',
      category_ids: 'category_ids',
      publishers: 'user_id'
    }

    let toLoadLists = ['advertisement_types']

    const addRolesOptions = () => {
      if(can('read', 'publishers-list')) {
        filtersOptions.unshift({
          text: 'Паблишер',
          value: 'publishers'
        })
        toLoadLists.push('publishers');
      }
    }

    addRolesOptions()

    handleLoadLists(toLoadLists, isLoadingLists)

    filterToShow.value = filtersOptions[0].value

    const disableForAdvertisementType = (el) => {
      if(formData.advertisement_types.length === 0) return false;
      switch(el.id){
        case 1:
        case 2:
          if(formData.advertisement_types.includes(3) || formData.advertisement_types.includes(4)) {
            return true
          }
          break;
        case 3:
          if(formData.advertisement_types.includes(1) || formData.advertisement_types.includes(2) || formData.advertisement_types.includes(4))
            return true
          break;
        case 4:
          if(formData.advertisement_types.includes(1) || formData.advertisement_types.includes(2) || formData.advertisement_types.includes(3))
            return true
          break;
        default:
          if(formData.advertisement_types.includes(1) || formData.advertisement_types.includes(2)) return true
          break;
      }
    }


    fetchCategories()
        .then(value => {
          categoryList.value = value.data.data
        })

    const pickMultiselect = (value, name) => {
      formData[name] = value;
    }

    const handleSubmit = async() => {
      const isValid = await form.value.validate();

      if(isValid) {
        const requestData = {
          ...formData
        }

        for (let prop in requestData) {
          if (Array.isArray(requestData[prop])) {
            requestData[prop] = requestData[prop].join(',')
          }
        }

        emit('submit', {...requestData})
      }
    }

    return {
      can,
      pickMultiselect,
      publishersList,
      filterToShow,
      proxyForFilterSelect,
      advertisementTypesList,
      categoryList,
      disableForAdvertisementType,
      namedLists,
      handleListsSearch,
      handlePaginateList,
      form,
      isLoadingLists,
      formData,
      setFilterToShow,
      filtersOptions,
      isEditor,
      handleSubmit,
      ...validations,
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.campaign__dropdown {
  width: 100%;
}

.placements-form {

  &__select-col {
    padding-right: 2rem;
  }
}

.campaign__dropdown .dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}

.selected-item {
  margin: 3px;
  cursor: pointer;
}

.multiselect, .multiselect__input, .multiselect__single {
  font-size: 14px;
}
</style>

