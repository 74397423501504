<template>
  <b-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :items="platforms" :fields="tableFields" no-local-sorting @sort-changed="sortChangeEmit">

    <template #cell(id)="row">
      <p style="word-break: normal; margin: 0;">{{ row.item.id }}</p>
    </template>

    <template #cell(advertisement_type)="row">
      <b-badge v-for="badge in row.item.advertisement_types" :key="badge.id" class="type" variant="primary">
        {{ badge.name }}
      </b-badge>
    </template>

    <template #cell(publisher)="row">
      {{ row.item.publisher.name }}
    </template>

    <template #cell(categories)="row">
      <b-badge v-for="badge in row.item.categories" :key="badge.id" class="type" variant="warning">
        {{ badge.name }}
      </b-badge>
    </template>

<!--    <template #cell(bundle)="row">-->
<!--      {{ row.item.name }}-->
<!--    </template>-->

    <template #cell(actions)="row">
      <b-dropdown right variant="flat-primary" no-caret>
        <template #button-content>
          <more-vertical-icon />
        </template>

        <b-dropdown-item :to="{ name: 'placements', params: { platforms: [row.item.id] }}">Размещения</b-dropdown-item>

        <b-dropdown-item v-if="can('read', 'manager-statistic')" :to="{ name: 'manager-statistic', params: { platforms: [row.item.id] }}">Статистика</b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item @click="handleEditUser(row.item.id)">Редактировать</b-dropdown-item>

<!--        <b-dropdown-item @click="handleToggleActive(row.item)">-->
<!--          {{ row.item.is_active ? 'Отключить' : 'Подключить' }}-->
<!--        </b-dropdown-item>-->
      </b-dropdown>
    </template>
  </b-table>
</template>

<script>
import { BTable, BCard, BRow, BCol, BButton, BBadge, BDropdown, BDropdownItem, BDropdownDivider } from 'bootstrap-vue'
import { MoreVerticalIcon } from 'vue-feather-icons'
import {useUtils as useAclUtils} from "@core/libs/acl"
import {computed} from "@vue/composition-api/dist/vue-composition-api"

const fields = [
  { key: 'id', label: 'ID', sortable: true },
  { key: 'publisher', label: 'Имя паблишера' },
  { key: 'advertisement_type', label: 'Тип' },
  { key: 'name', label: 'Название', sortable: true },
  { key: 'categories', label: 'Категории' },
  { key: 'actions', label: 'Действия' }
]

const publisherFields = [
  { key: 'id', label: 'ID', sortable: true },
  { key: 'name', label: 'Название', sortable: true },
  { key: 'advertisement_type', label: 'Тип' },
  { key: 'categories', label: 'Категории' },
  { key: 'actions', label: 'Действия' }
]

export default {
  components: {
    BTable,
    BCard,
    BRow,
    BCol,
    BButton,
    BBadge,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    MoreVerticalIcon
  },
  props: {
    platforms: {
      type: Array,
      required: true
    },
    sortBy: {
      type: String,
    },
    sortDesc: {
      type: Boolean,
    }
  },
  data() {
    return {
      fields
    }
  },
  methods: {
    handleEditUser(id) {
      this.$emit('edit', id)
    },
    sortChangeEmit(sort) {
      this.$emit('sort-change', sort);
    },
    handleToggleActive(data) {
      this.$emit('toggle-active', data)
    }
  },

  setup() {
    const {can} = useAclUtils();

    const tableFields = computed(() => {
      return can('read', 'publisher-name') ? fields : publisherFields;
    })
    return {
      can,
      tableFields
    }
  }
}
</script>

<style scoped lang="scss">
.type {
  margin: 3px;
}
</style>
